<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <h1>Mjesečna izvješća o plaćama</h1>
            </b-col>
            <b-col cols="6" class="print-show">
                <mara-table :item="selectedClient"></mara-table>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/monthlyPayroll/create">
                    <b-btn variant="primary">Novo izvješće</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="monthlyPayrolls">
            <b-col>
                <osta-table position
                            search-key="name"
                            :items="monthlyPayrolls"
                            :fields="monthlyPayrollsFields"
                            @rowClicked="goToMonthlyPayroll">
                </osta-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'monthlyPayrolls',
        data() {
            return {
                monthlyPayrolls : [],
                monthlyPayrollsFields: [
                    {
                        key:'date_from',
                        label: 'Datum od',
                        sortable: true
                    },
                    {
                        key: 'date_to',
                        label: 'Datum do',
                        sortable: true
                    }
                ]
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            goToMonthlyPayroll(id){
                console.log("method triggered")
            },
        }
    }
</script>
